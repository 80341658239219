/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  function setOffset() {
    $('.navbar-nav--sticky .dropdown-toggle').data('offset', '0,0');
  }

  var backTopEvents = {
    init: function() {
      var w = $('#scroll-line').waypoint({
        handler: function(direction) {
          if(direction === "down") {
            $('#back-to-top').addClass('is-fixed');
          } else if(direction === "up") {
            $('#back-to-top').removeClass('is-fixed');
          }
        }
      });
    }
  };

  var gestureEvents = {
    init: function() {
      if (window.matchMedia("(max-width: 767px)").matches) {
        har = [];
        $('.carousel').map(function(i, el) {
          var ha = new Hammer(el);
          har.push(ha);
          ha.on("swiperight", function(ev) {
            $(el).carousel('prev');
          });
          ha.on("swipeleft", function(ev) {
            $(el).carousel('next');
          });
        });
      }
    }
  };

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        var isOpen = false;
        backTopEvents.init();
        gestureEvents.init();
        $('.light-video').magnificPopup({
          type: 'iframe',
        });

        $('.light-gallery').magnificPopup({
          type: 'image',
          delegate: '.slide__link',
          gallery: {
            enabled:true,
          },
        });
        $('.btn-fixed-secondary').on("touchend", function(e) {
          e.preventDefault();
          return false;
        });
        $('.btn-fixed-secondary').on("mouseenter mouseleave click touchstart", function(e){
          if(e.type === 'touchstart') {
            e.preventDefault();
            $(this).off('mouseenter mouseleave click');
            if(isOpen === true) {
              window.location.href = $(this).data('href');
            }
            isOpen = true;
          } else if (e.type === 'click') {
            window.location.href = $(this).data('href');
          }
          $(this).toggleClass("hover");
        });

        var stickyOptions = {
          offset: 750,
          throttle: 50,
          classes: {
            clone:   'header--clone',
            stick:   'header--stick',
            unstick: 'header--unstick'
          },
          onStick: setOffset
        };
        var scroll = new SmoothScroll('[data-scroll]', {
          updateURL: false,
          header: '#top'
        });
        var hs = new Headhesive('.header--navigation', stickyOptions);

        var swiperCarousel = new Swiper('.swiper-container', {
          slidesPerView: 3,
          loop: true,
          pagination: {
            el: '.swiper-pagination',
            type: 'bullets',
          },
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          },
          breakpoints: {
            // when window width is <= 576px
            768: {
              slidesPerView: 2,
            },
            576: {
              slidesPerView: 1,
            }
          }
        });
        if (window.matchMedia("(max-width: 991px)").matches) {
          $('#top').removeClass('position-relative').addClass('sticky-top');
          hs.destroy();
        }
        $(window).resize(function() {
          if (window.matchMedia("(max-width: 991px)").matches) {
            var hs = new Headhesive('.header--navigation', stickyOptions);
            $('#top').removeClass('position-relative').addClass('sticky-top');
            hs.destroy();
          } else {
            $('#top').removeClass('sticky-top').addClass('position-relative');
          }
        });
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    },
    'request': {
      init: function() {
        // JavaScript to be fired on the request page, after the init JS
        var uri= URI(window.location.href);
        var uriValues = uri.query(true);
        var y = parseInt(uriValues.y)+1;
        var r = parseInt(uriValues.r)+1;
        var selectY = $('.select-y :nth-child(' + y +')');
        var selectR = $('.select-r :nth-child(' + r +')');

        selectY.prop("selected", true);
        selectR.prop("selected", true);
      },
      finalize: function() {
      }
    },
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
